var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    attrs: {
      "color": "grey lighten-5",
      "height": "100%",
      "width": "400"
    }
  }, [_c('v-row', {
    staticClass: "ma-0"
  }, [_vm.user ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('info-card-user', _vm._b({}, 'info-card-user', {
    user: _vm.user
  }, false))], 1) : _vm._e(), _vm.room ? _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('info-card-chat', _vm._b({}, 'info-card-chat', {
    room: _vm.room
  }, false))], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }