<template>
    <v-list ref="list">
        <template v-for="message in messages">
            <message-item v-bind="{ room, message, isScrollFixed }" :key="message._id" />
        </template>
        <template>
            <message-load v-bind="{ room, isScrollFixed }" />
        </template>
    </v-list>
</template>

<script>
import MessageItem from "./message-item.vue";
import MessageLoad from "./message-load.vue";

export default {
    components: {
        MessageItem,
        MessageLoad,
    },
    props: {
        room: { type: Object, default: () => ({}) },
        messages: { type: Array, default: () => [] },
    },
    data: () => ({
        isInitiated: false,
        isScrollFixed: true,
    }),
    mounted() {
        this.init();
    },
    watch: {
        room() {
            this.init();
        },
    },
    methods: {
        init() {
            if (!this.isInitiated) {
                if (this.room?._id) {
                    this.$nextTick(() => {
                        const el = this.$refs["list"]?.$el;
                        if (el) {
                            el.addEventListener("scroll", this.onScroll);
                            el.scrollTop = el.scrollHeight;
                            this.isInitiated = true;
                        }
                    });
                }
            }
        },
        onScroll(event) {
            const { scrollHeight, scrollTop, clientHeight } = event.target;
            this.isScrollFixed = scrollHeight - (scrollTop + clientHeight) < 30;
        },
    },
};
</script>

<style></style>
