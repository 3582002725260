var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('message-list', _vm._b({}, 'message-list', {
    room: _vm.room,
    messages: _vm.messages
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }