<template>
    <v-list dense class="pa-0">
        <template v-for="room in rooms">
            <chat-room-item v-bind="{ room }" :key="room._id" />
        </template>
        <template v-if="!rooms.length">
            <v-list-item v-if="message__none" class="py-1">
                <v-list-item-subtitle> {{ message__none }} </v-list-item-subtitle>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
import ChatRoomItem from "./chat-room-item.vue";

export default {
    components: {
        ChatRoomItem,
    },
    props: {
        rooms: { type: Array, default: () => [] },
        message__none: { type: String, default: "진행 중인 상담이 없습니다" },
    },
};
</script>

<style></style>
