<template>
    <v-sheet color="grey lighten-5" height="100%" width="400">
        <v-row class="ma-0">
            <v-col cols="12" v-if="user">
                <info-card-user v-bind="{ user }" />
            </v-col>
            <v-col cols="12" v-if="room">
                <info-card-chat v-bind="{ room }" />
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import InfoCardChat from "./info/info-card-chat.vue";
import InfoCardUser from "./info/info-card-user.vue";

export default {
    components: {
        InfoCardUser,
        InfoCardChat,
    },
    props: {
        room: { type: Object, default: () => ({}) },
    },
    computed: {
        user() {
            return this.room.owner?.user;
        },
    },
};
</script>

<style></style>
