var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    staticClass: "align-center",
    attrs: {
      "flat": "",
      "color": "transparent"
    }
  }, [_c('v-toolbar-title', [_vm._v(" " + _vm._s(_vm.title) + " "), _c('span', {
    staticClass: "caption"
  }, [_vm._v(" " + _vm._s(_vm.subtitle))])]), _vm.chip ? [_c('v-chip', {
    staticClass: "ml-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.chip) + " ")])] : _vm._e(), _vm.chip ? [_c('view-head-menu', _vm._b({}, 'view-head-menu', {
    room: _vm.room
  }, false))] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }