var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shows ? _c('v-list-item', {
    ref: "item",
    staticClass: "d-flex",
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-avatar', {
    attrs: {
      "color": "grey lighten-4"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v(" " + _vm._s(_vm.icon) + " ")])], 1), _c('v-list-item-content', {
    staticStyle: {
      "flex": "0 0 auto"
    }
  }, [_c('v-card', {
    staticClass: "align-self-left",
    attrs: {
      "flat": "",
      "rounded": "lg",
      "color": "grey lighten-4",
      "max-width": "90%",
      "min-width": "200"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-2"
  }, [_c('v-progress-linear', {
    staticClass: "my-2",
    attrs: {
      "stream": "",
      "indeterminate": "",
      "buffer-value": "0",
      "height": "4"
    }
  })], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }