<template></template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState(["scope"]),
    },
    methods: {
        scopeIncludes(criterion) {
            return this.scope?.includes?.(criterion);
        },
    },
    mounted() {
        // if (this.scopeIncludes("console")) this.$router.replace("/counsel/dashboard");
        if (this.scopeIncludes("console")) this.$router.replace("/counsel/site-help-chats");
        if (this.scopeIncludes("counsel")) this.$router.replace("/counsel/site-help-chats");
    },
};
</script>
