var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    staticClass: "pa-0",
    attrs: {
      "dense": ""
    }
  }, [_vm._l(_vm.rooms, function (room) {
    return [_c('chat-room-item', _vm._b({
      key: room._id
    }, 'chat-room-item', {
      room
    }, false))];
  }), !_vm.rooms.length ? [_vm.message__none ? _c('v-list-item', {
    staticClass: "py-1"
  }, [_c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.message__none) + " ")])], 1) : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }