<template>
    <v-app-bar flat class="align-center" color="transparent">
        <v-toolbar-title>
            {{ title }} <span class="caption"> {{ subtitle }}</span>
        </v-toolbar-title>
        <template v-if="chip">
            <v-chip small class="ml-1"> {{ chip }} </v-chip>
        </template>
        <template v-if="chip">
            <view-head-menu v-bind="{ room }" />
        </template>
    </v-app-bar>
</template>

<script>
import { SITE_HELP_CHAT_ROOM_STAGES } from "@/assets/variables";

import ViewHeadMenu from "./view-head-menu.vue";

export default {
    components: {
        ViewHeadMenu,
    },
    props: {
        room: { type: Object, default: () => ({}) },
    },
    computed: {
        user() {
            return this.room?.owner?.user;
        },

        title() {
            if (!this.room) return "상담을 선택해주세요";
            if (!this.user) return "비회원";
            return this.user.name;
        },
        subtitle() {
            if (this.user) return `(${this.user.username})`;
        },
        chip() {
            return SITE_HELP_CHAT_ROOM_STAGES[this.room?.stage]?.text;
        },
    },
};
</script>

<style></style>
