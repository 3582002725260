var render = function render(){
  var _vm$message, _vm$message$file, _vm$message2, _vm$message2$file;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', {
    ref: "item",
    staticClass: "d-flex",
    class: {
      'flex-row-reverse': _vm.reverse
    },
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item-avatar', {
    class: {
      'mr-0 ml-4': _vm.reverse
    },
    attrs: {
      "color": "grey lighten-4"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v(" " + _vm._s(_vm.icon) + " ")])], 1), _c('v-list-item-content', {
    staticStyle: {
      "flex": "0 1 auto"
    }
  }, [_c('v-card', {
    staticClass: "align-self-left",
    attrs: {
      "flat": "",
      "rounded": "lg",
      "color": "grey lighten-4"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-2",
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm.message.file ? [_c('v-chip', {
    attrs: {
      "href": (_vm$message = _vm.message) === null || _vm$message === void 0 ? void 0 : (_vm$message$file = _vm$message.file) === null || _vm$message$file === void 0 ? void 0 : _vm$message$file.url,
      "download": ""
    }
  }, [_c('v-icon', {
    staticClass: "mr-1"
  }, [_vm._v("mdi-file")]), _c('span', {
    staticClass: "text-truncate",
    staticStyle: {
      "max-width": "250px"
    }
  }, [_vm._v(" " + _vm._s((_vm$message2 = _vm.message) === null || _vm$message2 === void 0 ? void 0 : (_vm$message2$file = _vm$message2.file) === null || _vm$message2$file === void 0 ? void 0 : _vm$message2$file.fileName) + " ")])], 1)] : [_c('span', [_vm._v(" " + _vm._s(_vm.message.content) + " ")])]], 2)], 1)], 1), _c('v-list-item-action', {
    staticClass: "align-self-end",
    class: {
      'ml-0 mr-4': _vm.reverse
    }
  }, [_c('v-list-item-action-text', [_vm._v(" " + _vm._s(_vm.$dayjs(_vm.message.createdAt).format("HH:mm")) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }