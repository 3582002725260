var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('article', {
    staticClass: "d-flex flex-column",
    staticStyle: {
      "flex": "1 0 auto"
    }
  }, [_c('view-head', _vm._b({
    staticStyle: {
      "flex": "0 0 auto"
    }
  }, 'view-head', {
    room: _vm.room
  }, false)), _c('v-divider'), _vm.room ? [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": ""
    }
  }, [_c('view-body', _vm._b({
    staticStyle: {
      "flex": "1 0 auto",
      "overflow-y": "auto",
      "height": "calc(100vh - 64px - 1px - 152px)"
    }
  }, 'view-body', {
    room: _vm.room
  }, false)), _c('view-foot', _vm._b({
    staticStyle: {
      "flex": "0 0 auto"
    }
  }, 'view-foot', {
    room: _vm.room
  }, false))], 1), _c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "md": "auto"
    }
  }, [_c('view-side', _vm._b({}, 'view-side', {
    room: _vm.room
  }, false))], 1)], 1)] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }