<template>
    <v-list-item dense class="d-flex" :class="{ 'flex-row-reverse': reverse }" ref="item">
        <v-list-item-avatar color="grey lighten-4" :class="{ 'mr-0 ml-4': reverse }">
            <v-icon color="grey lighten-1"> {{ icon }} </v-icon>
        </v-list-item-avatar>
        <v-list-item-content style="flex: 0 1 auto">
            <v-card flat rounded="lg" color="grey lighten-4" class="align-self-left">
                <v-card-text class="pa-2" style="white-space: pre-line">
                    <template v-if="message.file">
                        <v-chip :href="message?.file?.url" download>
                            <v-icon class="mr-1">mdi-file</v-icon>
                            <span class="text-truncate" style="max-width: 250px">
                                {{ message?.file?.fileName }}
                            </span>
                        </v-chip>
                    </template>
                    <template v-else>
                        <span> {{ message.content }} </span>
                    </template>
                </v-card-text>
            </v-card>
        </v-list-item-content>
        <v-list-item-action class="align-self-end" :class="{ 'ml-0 mr-4': reverse }">
            <v-list-item-action-text>
                {{ $dayjs(message.createdAt).format("HH:mm") }}
            </v-list-item-action-text>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import { CHAT_MESSAGE_TYPES } from "@/assets/variables";
export default {
    props: {
        room: { type: Object, default: () => ({}) },
        message: { type: Object, default: () => ({}) },
        isScrollFixed: { type: Boolean, default: false },
    },
    computed: {
        icon() {
            switch (this.message.type) {
                case CHAT_MESSAGE_TYPES.AUTO_NOTE.value: {
                    return "mdi-robot";
                }
                default: {
                    return "mdi-account";
                }
            }
        },
        reverse() {
            return this.message.type == CHAT_MESSAGE_TYPES.ADMIN_END.value;
        },
    },
    mounted() {
        if (this.isScrollFixed) {
            this.$nextTick(() => {
                const el = this.$refs["item"].$el;
                if (el) el.scrollIntoView({ behavior: "smooth" });
            });
        }
    },
};
</script>

<style></style>
