<template>
    <v-list-item v-if="shows" class="d-flex" dense ref="item">
        <v-list-item-avatar color="grey lighten-4">
            <v-icon color="grey lighten-1"> {{ icon }} </v-icon>
        </v-list-item-avatar>
        <v-list-item-content style="flex: 0 0 auto">
            <v-card flat rounded="lg" color="grey lighten-4" class="align-self-left" max-width="90%" min-width="200">
                <v-card-text class="pa-2">
                    <v-progress-linear stream indeterminate buffer-value="0" height="4" class="my-2" />
                </v-card-text>
            </v-card>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import { SITE_HELP_CHAT_ROOM_STAGES } from "@/assets/variables";

export default {
    props: {
        room: { type: Object, default: () => ({}) },
        isScrollFixed: { type: Boolean, default: false },
    },
    computed: {
        awaitingManager() {
            return this.room?.stage == SITE_HELP_CHAT_ROOM_STAGES.AWAITING_MANAGER.value;
        },
        isUserTyping() {
            return this.room?.isUserTyping;
        },

        shows() {
            return this.awaitingManager || this.isUserTyping;
        },

        icon() {
            if (this.isUserTyping) return "mdi-account";
            if (this.awaitingManager) return "mdi-robot";
        },
    },
    mounted() {
        this.scrollIntoView();
    },
    watch: {
        shows() {
            this.scrollIntoView();
        },
    },
    methods: {
        scrollIntoView() {
            if (!this.shows || !this.isScrollFixed) return;

            this.$nextTick(() => {
                const el = this.$refs["item"]?.$el;
                if (el) el.scrollIntoView({ behavior: "smooth" });
            });
        },
    },
};
</script>

<style></style>
