<template>
    <v-card flat v-bind="{ disabled }">
        <v-card-text>
            <v-card outlined rounded="lg">
                <v-textarea v-model="content" dense flat solo hide-details rows="4" background-color="grey lighten-5" @keydown.enter="send">
                    <template #append>
                        <v-btn small icon tile absolute right bottom class="mb-n1 mr-n1" color="primary" @click="send">
                            <v-icon>mdi-send</v-icon>
                        </v-btn>
                    </template>
                </v-textarea>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { CHAT_MESSAGE_TYPES, SITE_HELP_CHAT_ROOM_STAGES } from "@/assets/variables";

export default {
    props: {
        room: { type: Object, default: () => ({}) },
    },
    data: () => ({
        content: null,
    }),
    computed: {
        isManagerTyping() {
            return 0 < (this.content?.replaceAll?.(/[\s\r\n]/g, "")?.length || 0);
        },
        disabled() {
            return this.room.stage != SITE_HELP_CHAT_ROOM_STAGES.COUNSEL_PROGRESS.value;
        },
    },
    watch: {
        isManagerTyping(isManagerTyping) {
            this.sendRoomManagerTyping({ _room: this.room._id, isManagerTyping });
        },
    },
    methods: {
        ...mapActions("counsel", ["sendMessage", "updateMessageItem", "sendRoomManagerTyping"]),
        send(event) {
            if (event.shiftKey || event.altKey || event.ctrlKey) return;

            const { content } = this;

            const message = { _room: this.room._id, content, tempId: Math.random(), type: CHAT_MESSAGE_TYPES.ADMIN_END.value };
            this.updateMessageItem({ message });
            this.sendMessage({ message });

            this.$nextTick(() => (this.content = null));
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-input__slot {
        padding-right: 0 !important;
    }
}
</style>
