<template>
    <view-section-card title="고객정보" hideDivider>
        <template #actions>
            <v-icon small @click="openLink">mdi-open-in-new</v-icon>
        </template>
        <v-card-text class="mt-n2">
            <v-row align="center" class="ma-n1">
                <v-col cols="auto" class="pa-1">
                    <v-avatar size="32" color="grey lighten-4">
                        <v-icon size="20">mdi-account</v-icon>
                    </v-avatar>
                </v-col>
                <v-col class="pa-1 text-subtitle-1 font-weight-bold">
                    {{ user.name }}
                </v-col>
            </v-row>
            <template v-for="(item, index) of descriptions">
                <v-row :key="index" class="ma-n1">
                    <v-col cols="auto" class="pa-1">
                        <v-icon x-small>{{ item.icon }}</v-icon>
                    </v-col>
                    <v-col cols="auto" class="pa-1 caption">
                        {{ item.term }}
                    </v-col>
                    <v-col class="pa-1 caption font-weight-bold">
                        {{ item.desc }}
                    </v-col>
                </v-row>
            </template>
        </v-card-text>
    </view-section-card>
</template>

<script>
import { initUser } from "@/assets/variables";

import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";

export default {
    components: {
        ViewSectionCard,
    },
    props: {
        user: { type: Object, default: initUser },
    },
    computed: {
        descriptions() {
            const items = [{ term: "휴대폰 번호", desc: this.user?.phone?.phoneNumberFormat?.(), icon: "mdi-phone" }];

            if (this.user?.meta?.CustNo) {
                items.push({ term: "연동번호", desc: this.user?.meta?.CustNo, icon: "mdi-barcode" });
            }
            if (this.user?.business?.no) {
                items.push({ term: "사업자등록번호", desc: this.user?.business?.no, icon: "mdi-domain" });
            }
            if (0 < (this.user?.managers?.length || 0)) {
                items.push({ term: "영업담당자", desc: this.user?.managers.map(({ name, username }) => `${username}(${name})`).join(", "), icon: "mdi-domain" });
            }

            return items;
        },
    },
    methods: {
        openLink() {
            window.open(`/console/users/${this.user._id}`, "_blank");
        },
    },
};
</script>
