var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', _vm._b({
    attrs: {
      "title": "이전상담",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              _vm.shows = !_vm.shows;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.shows ? "mdi-chevron-down" : "mdi-chevron-up") + " ")])];
      },
      proxy: true
    }])
  }, 'view-section-card', {
    loading: _vm.loading
  }, false), [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shows,
      expression: "shows"
    }],
    staticStyle: {
      "overflow-y": "auto"
    },
    attrs: {
      "max-height": "calc(58px * 3)"
    }
  }, [_c('v-divider'), _c('chat-room-list', _vm._b({
    attrs: {
      "message__none": "이전 상담이 없습니다"
    }
  }, 'chat-room-list', {
    rooms: _vm.rooms
  }, false)), _vm.more ? _c('v-btn', {
    attrs: {
      "text": "",
      "block": ""
    },
    on: {
      "click": _vm.searchMore
    }
  }, [_vm._v(" 더보기 ")]) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }