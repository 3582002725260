<template>
    <v-navigation-drawer app permanent class="d-flex flex-column ml-14">
        <v-toolbar flat tile>
            <v-toolbar-title>
                {{ title }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn-toggle v-model="mode" dense borderless tile>
                <v-btn value="search" x-small text tile width="32" height="32">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-btn value="filter" x-small text tile width="32" height="32">
                    <v-icon>mdi-filter-variant</v-icon>
                </v-btn>
            </v-btn-toggle>
        </v-toolbar>
        <v-layout column style="height: calc(100% - 64px - 1px); flex: 1 0 auto">
            <v-expand-transition>
                <v-sheet v-show="mode == 'search'">
                    <v-card-text class="mt-n4 mb-n2">
                        <v-text-field v-model="searchValue" v-bind="attrs_input" @keydown.enter="emit({ searchValue })" clearable @click:clear="emit({ searchValue: null })" prepend-inner-icon="mdi-magnify" />
                    </v-card-text>
                </v-sheet>
            </v-expand-transition>
            <v-expand-transition>
                <v-sheet v-show="mode == 'filter'">
                    <v-card-text class="mt-n4 mb-n2">
                        <v-btn-toggle v-model="searchStage" @change="emit({ searchStage })" class="d-flex justify-stretch" color="primary" mandatory>
                            <template v-for="{ value, text } in stages">
                                <v-btn v-bind="{ value }" :key="text" class="caption" height="40" style="flex: 1 0 auto" color="white">
                                    {{ text }}
                                </v-btn>
                            </template>
                        </v-btn-toggle>
                    </v-card-text>
                </v-sheet>
            </v-expand-transition>
            <slot />
        </v-layout>
    </v-navigation-drawer>
</template>

<script>
import { attrs_input, SITE_HELP_CHAT_ROOM_STAGES } from "@/assets/variables";
import { mapMutations, mapState } from "vuex";

const stages = Object.values(SITE_HELP_CHAT_ROOM_STAGES).map((item) => ({ ...item, text: item.text.replaceAll("상담", "") }));
stages.splice(0, 1, { value: null, text: "전체" });

export default {
    props: {
        title: { type: String, default: "실시간 상담" },
    },
    data: () => ({
        searchValue: null,
        searchStage: null,

        mode: null,

        attrs_input,

        stages,
    }),
    computed: {
        ...mapState("counsel", ["filter"]),
    },
    mounted() {
        this.init();
    },
    watch: {
        filter() {
            this.init();
        },
    },
    methods: {
        ...mapMutations("counsel", ["setFilter"]),
        init() {
            this.searchValue = this.filter.searchValue;
            this.searchStage = this.filter.searchStage;
        },
        emit({ searchValue, searchStage }) {
            const { ...filter } = this.filter;

            console.log("emit: {");
            console.log({ searchValue, searchStage });
            console.log(filter);

            if (searchValue !== undefined) filter.searchValue = searchValue;
            if (searchStage !== undefined) filter.searchStage = searchStage;

            console.log(filter);
            console.log("}");

            this.setFilter({ filter });
        },
    },
};
</script>

<style></style>
