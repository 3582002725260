<template>
    <v-menu offset-y left content-class="elevation-0">
        <template #activator="{ attrs, on }">
            <v-app-bar-nav-icon class="transparent" small tile v-bind="attrs" v-on="on">
                <v-icon small>mdi-dots-vertical</v-icon>
            </v-app-bar-nav-icon>
        </template>
        <v-list dense outlined>
            <v-list-item :disabled="!awaitingManager" @click="joinRoom({ _room: room._id })">
                <v-list-item-title>상담시작</v-list-item-title>
            </v-list-item>
            <v-list-item :disabled="!counselProgress" @click="exitRoom({ _room: room._id })">
                <v-list-item-title>상담종료</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { SITE_HELP_CHAT_ROOM_STAGES } from "@/assets/variables";
import { mapActions } from "vuex";
export default {
    props: {
        room: { type: Object, default: () => ({}) },
    },
    methods: {
        ...mapActions("counsel", ["joinRoom", "exitRoom"]),
    },
    computed: {
        awaitingManager() {
            return this.room.stage == SITE_HELP_CHAT_ROOM_STAGES.AWAITING_MANAGER.value;
        },
        counselProgress() {
            return this.room.stage == SITE_HELP_CHAT_ROOM_STAGES.COUNSEL_PROGRESS.value;
        },
    },
};
</script>

<style></style>
