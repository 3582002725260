<template>
    <message-list v-bind="{ room, messages }" />
</template>

<script>
import MessageList from "../../messages/message-list.vue";

export default {
    components: {
        MessageList,
    },
    props: {
        room: { type: Object, default: () => ({}) },
    },
    computed: {
        messages() {
            return this.room?.messages || [];
        },
    },
};
</script>

<style></style>
