var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    attrs: {
      "flat": ""
    }
  }, 'v-card', {
    disabled: _vm.disabled
  }, false), [_c('v-card-text', [_c('v-card', {
    attrs: {
      "outlined": "",
      "rounded": "lg"
    }
  }, [_c('v-textarea', {
    attrs: {
      "dense": "",
      "flat": "",
      "solo": "",
      "hide-details": "",
      "rows": "4",
      "background-color": "grey lighten-5"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.send.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-btn', {
          staticClass: "mb-n1 mr-n1",
          attrs: {
            "small": "",
            "icon": "",
            "tile": "",
            "absolute": "",
            "right": "",
            "bottom": "",
            "color": "primary"
          },
          on: {
            "click": _vm.send
          }
        }, [_c('v-icon', [_vm._v("mdi-send")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v;
      },
      expression: "content"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }