var render = function render(){
  var _vm$message;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list-item', {
    staticClass: "py-1",
    staticStyle: {
      "margin-right": "1px"
    },
    on: {
      "click": _vm.toggle
    }
  }, [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "d-flex align-center"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]), _c('v-spacer'), _c('count-down', {
    attrs: {
      "value": (_vm$message = _vm.message) === null || _vm$message === void 0 ? void 0 : _vm$message.createdAt,
      "mode": "past"
    }
  })], 1), _c('v-list-item-subtitle', [_c('span', [_vm._v(" " + _vm._s(_vm.content) + " ")])])], 1), _vm.isClosed ? [_c('v-overlay', {
    attrs: {
      "absolute": "",
      "color": "white"
    }
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }