var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    staticClass: "d-flex flex-column ml-14",
    attrs: {
      "app": "",
      "permanent": ""
    }
  }, [_c('v-toolbar', {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('v-toolbar-title', [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-spacer'), _c('v-btn-toggle', {
    attrs: {
      "dense": "",
      "borderless": "",
      "tile": ""
    },
    model: {
      value: _vm.mode,
      callback: function ($$v) {
        _vm.mode = $$v;
      },
      expression: "mode"
    }
  }, [_c('v-btn', {
    attrs: {
      "value": "search",
      "x-small": "",
      "text": "",
      "tile": "",
      "width": "32",
      "height": "32"
    }
  }, [_c('v-icon', [_vm._v("mdi-magnify")])], 1), _c('v-btn', {
    attrs: {
      "value": "filter",
      "x-small": "",
      "text": "",
      "tile": "",
      "width": "32",
      "height": "32"
    }
  }, [_c('v-icon', [_vm._v("mdi-filter-variant")])], 1)], 1)], 1), _c('v-layout', {
    staticStyle: {
      "height": "calc(100% - 64px - 1px)",
      "flex": "1 0 auto"
    },
    attrs: {
      "column": ""
    }
  }, [_c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.mode == 'search',
      expression: "mode == 'search'"
    }]
  }, [_c('v-card-text', {
    staticClass: "mt-n4 mb-n2"
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "clearable": "",
      "prepend-inner-icon": "mdi-magnify"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.emit({
          searchValue: _vm.searchValue
        });
      },
      "click:clear": function ($event) {
        return _vm.emit({
          searchValue: null
        });
      }
    },
    model: {
      value: _vm.searchValue,
      callback: function ($$v) {
        _vm.searchValue = $$v;
      },
      expression: "searchValue"
    }
  }, 'v-text-field', _vm.attrs_input, false))], 1)], 1)], 1), _c('v-expand-transition', [_c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.mode == 'filter',
      expression: "mode == 'filter'"
    }]
  }, [_c('v-card-text', {
    staticClass: "mt-n4 mb-n2"
  }, [_c('v-btn-toggle', {
    staticClass: "d-flex justify-stretch",
    attrs: {
      "color": "primary",
      "mandatory": ""
    },
    on: {
      "change": function ($event) {
        return _vm.emit({
          searchStage: _vm.searchStage
        });
      }
    },
    model: {
      value: _vm.searchStage,
      callback: function ($$v) {
        _vm.searchStage = $$v;
      },
      expression: "searchStage"
    }
  }, [_vm._l(_vm.stages, function (_ref) {
    var value = _ref.value,
      text = _ref.text;
    return [_c('v-btn', _vm._b({
      key: text,
      staticClass: "caption",
      staticStyle: {
        "flex": "1 0 auto"
      },
      attrs: {
        "height": "40",
        "color": "white"
      }
    }, 'v-btn', {
      value
    }, false), [_vm._v(" " + _vm._s(text) + " ")])];
  })], 2)], 1)], 1)], 1), _vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }