var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-content', [_c('v-container', {
    staticClass: "pa-0"
  }, [_c('counsel-aside', {
    staticStyle: {
      "flex": "0 0 auto"
    },
    attrs: {
      "title": "실시간 상담"
    }
  }, [_c('chat-room-list', _vm._b({}, 'chat-room-list', {
    rooms: _vm.rooms
  }, false)), _vm.moreRoomProps.shows ? [_c('v-btn', _vm._b({
    staticStyle: {
      "flex": "0 0 auto"
    },
    attrs: {
      "text": "",
      "block": ""
    },
    on: {
      "click": _vm.search
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(" 더보기 ")])] : _vm._e()], 2), _c('chat-room-view', _vm._b({
    staticStyle: {
      "flex": "1 0 auto"
    }
  }, 'chat-room-view', {
    room: _vm.room
  }, false))], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }