var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-menu', {
    attrs: {
      "offset-y": "",
      "left": "",
      "content-class": "elevation-0"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-app-bar-nav-icon', _vm._g(_vm._b({
          staticClass: "transparent",
          attrs: {
            "small": "",
            "tile": ""
          }
        }, 'v-app-bar-nav-icon', attrs, false), on), [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-dots-vertical")])], 1)];
      }
    }])
  }, [_c('v-list', {
    attrs: {
      "dense": "",
      "outlined": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "disabled": !_vm.awaitingManager
    },
    on: {
      "click": function ($event) {
        return _vm.joinRoom({
          _room: _vm.room._id
        });
      }
    }
  }, [_c('v-list-item-title', [_vm._v("상담시작")])], 1), _c('v-list-item', {
    attrs: {
      "disabled": !_vm.counselProgress
    },
    on: {
      "click": function ($event) {
        return _vm.exitRoom({
          _room: _vm.room._id
        });
      }
    }
  }, [_c('v-list-item-title', [_vm._v("상담종료")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }