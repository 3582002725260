var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('view-section-card', {
    attrs: {
      "title": "고객정보",
      "hideDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": _vm.openLink
          }
        }, [_vm._v("mdi-open-in-new")])];
      },
      proxy: true
    }])
  }, [_c('v-card-text', {
    staticClass: "mt-n2"
  }, [_c('v-row', {
    staticClass: "ma-n1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pa-1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-avatar', {
    attrs: {
      "size": "32",
      "color": "grey lighten-4"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v("mdi-account")])], 1)], 1), _c('v-col', {
    staticClass: "pa-1 text-subtitle-1 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.user.name) + " ")])], 1), _vm._l(_vm.descriptions, function (item, index) {
    return [_c('v-row', {
      key: index,
      staticClass: "ma-n1"
    }, [_c('v-col', {
      staticClass: "pa-1",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-icon', {
      attrs: {
        "x-small": ""
      }
    }, [_vm._v(_vm._s(item.icon))])], 1), _c('v-col', {
      staticClass: "pa-1 caption",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(" " + _vm._s(item.term) + " ")]), _c('v-col', {
      staticClass: "pa-1 caption font-weight-bold"
    }, [_vm._v(" " + _vm._s(item.desc) + " ")])], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }