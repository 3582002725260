<template>
    <article class="d-flex flex-column" style="flex: 1 0 auto">
        <view-head v-bind="{ room }" style="flex: 0 0 auto" />
        <v-divider />
        <template v-if="room">
            <v-row no-gutters>
                <v-col cols="12" sm="">
                    <view-body v-bind="{ room }" style="flex: 1 0 auto; overflow-y: auto; height: calc(100vh - 64px - 1px - 152px)" />
                    <view-foot v-bind="{ room }" style="flex: 0 0 auto" />
                </v-col>
                <v-col md="auto" class="d-none d-md-block">
                    <view-side v-bind="{ room }" />
                </v-col>
            </v-row>
        </template>
    </article>
</template>

<script>
import ViewHead from "./view/view-head.vue";
import ViewBody from "./view/view-body.vue";
import ViewFoot from "./view/view-foot.vue";
import ViewSide from "./view/view-info.vue";

export default {
    components: {
        ViewHead,
        ViewBody,
        ViewFoot,
        ViewSide,
    },
    props: {
        room: { type: Object, default: () => ({}) },
    },
};
</script>

<style></style>
