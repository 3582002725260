<template>
    <v-list-item class="py-1" style="margin-right: 1px" @click="toggle">
        <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
                <span> {{ subtitle }} </span>
                <v-spacer />
                <count-down :value="message?.createdAt" mode="past" />
            </v-list-item-title>
            <v-list-item-subtitle>
                <span> {{ content }} </span>
            </v-list-item-subtitle>
        </v-list-item-content>
        <template v-if="isClosed"> <v-overlay absolute color="white" /> </template>
    </v-list-item>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { AUTO_NOTE_CHAT_MESSAGE_CODES } from "@/assets/variables";

import countDown from "@/components/dumb/count-down.vue";

export default {
    components: { countDown },
    props: {
        room: { type: Object, default: () => ({}) },
    },
    computed: {
        ...mapState("counsel", ["_room"]),
        user() {
            return this.room?.owner?.user;
        },
        subtitle() {
            if (!this.user) return "비회원";
            return this.user.name;
        },
        message() {
            return [...(this.room.messages || [this.room.message__last])].pop();
        },
        content() {
            switch (this.message?.code) {
                case AUTO_NOTE_CHAT_MESSAGE_CODES.PLEASE_STAND_BY.value: {
                    return "새로운 상담요청";
                }

                default: {
                    return this.message?.content || "-";
                }
            }
        },
        isClosed() {
            return this.room.isClosed;
        },
    },
    methods: {
        ...mapMutations("counsel", ["setRoom"]),
        toggle() {
            const { _id: _room } = this.room || {};

            if (this._room == _room) {
                this.setRoom({ _room: null });
            } else {
                this.setRoom({ _room });
            }
        },
    },
};
</script>

<style></style>
