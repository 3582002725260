<template>
    <v-content>
        <v-container class="pa-0">
            <counsel-aside title="실시간 상담" style="flex: 0 0 auto">
                <chat-room-list v-bind="{ rooms }" />
                <template v-if="moreRoomProps.shows">
                    <v-btn text block style="flex: 0 0 auto" v-bind="{ loading }" @click="search"> 더보기 </v-btn>
                </template>
            </counsel-aside>
            <chat-room-view v-bind="{ room }" style="flex: 1 0 auto" />
        </v-container>
    </v-content>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import ChatRoomList from "@/components/counsel/chats/chat-room-list.vue";
import ChatRoomView from "@/components/counsel/chats/chat-room-view.vue";
import CounselAside from "@/components/counsel/counsel-aside.vue";
import { SITE_HELP_CHAT_ROOM_STAGES } from "@/assets/variables";

export default {
    components: {
        ChatRoomList,
        ChatRoomView,
        CounselAside,
    },
    computed: {
        ...mapState("counsel", ["loading"]),
        ...mapGetters("counsel", ["room", "rooms", "moreRoomProps"]),
    },
    methods: {
        ...mapMutations("counsel", ["setLoading"]),
        ...mapActions("counsel", ["getRooms"]),

        search() {
            this.setLoading({ loading: true });

            const { headers } = this.moreRoomProps.request;
            this.getRooms({ params: { stage: { $in: [SITE_HELP_CHAT_ROOM_STAGES.COUNSEL_COMPLETE.value] } }, headers });
        },
    },
};
</script>

<style></style>
