var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    ref: "list"
  }, [_vm._l(_vm.messages, function (message) {
    return [_c('message-item', _vm._b({
      key: message._id
    }, 'message-item', {
      room: _vm.room,
      message,
      isScrollFixed: _vm.isScrollFixed
    }, false))];
  }), [_c('message-load', _vm._b({}, 'message-load', {
    room: _vm.room,
    isScrollFixed: _vm.isScrollFixed
  }, false))]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }