<template>
    <view-section-card title="이전상담" hideDivider v-bind="{ loading }">
        <template #actions>
            <v-icon small @click="shows = !shows">
                {{ shows ? "mdi-chevron-down" : "mdi-chevron-up" }}
            </v-icon>
        </template>
        <v-expand-transition>
            <v-sheet v-show="shows" max-height="calc(58px * 3)" style="overflow-y: auto">
                <v-divider />
                <chat-room-list v-bind="{ rooms }" message__none="이전 상담이 없습니다" />
                <v-btn v-if="more" text block @click="searchMore"> 더보기 </v-btn>
            </v-sheet>
        </v-expand-transition>
    </view-section-card>
</template>

<script>
import api from "@/api";

import ChatRoomList from "@/components/counsel/chats/chat-room-list.vue";
import ViewSectionCard from "@/components/console/dumb/view-section-card.vue";
import { CHAT_ROOM_TYPES } from "@/assets/variables";

export default {
    components: {
        ChatRoomList,
        ViewSectionCard,
    },
    props: {
        room: { type: Object, default: () => ({}) },
    },
    data: () => ({
        rooms: [],

        page: 1,
        limit: 10,
        summary: { totalCount: 0 },

        shows: true,
        loading: false,
    }),
    computed: {
        skip() {
            return (this.page - 1) * this.limit;
        },
        more() {
            return 0 < this.summary.totalCount - this.rooms.length;
        },
        params() {
            const query = {};

            query._id = { $nin: this.room?._id };
            query.isClosed = true;
            query.type = CHAT_ROOM_TYPES.SITE_HELP.value;

            if (![undefined, null].includes(this.room?.owner?._user)) {
                query["owner._user"] = this.room.owner._user;
            }

            if (![undefined, null].includes(this.room?.owner?.sessionId)) {
                query["owner.sessionId"] = this.room.owner.sessionId;
            }

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        "room._id"() {
            this.init();
        },
        page() {
            this.search();
        },
    },
    methods: {
        init() {
            this.rooms = [];
            this.summary = { totalCount: 0 };
            this.loading = false;

            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { skip, limit, params } = this;
                const { summary, rooms } = await api.console.chats.rooms.gets({
                    headers: { skip, limit },
                    params,
                });
                this.summary = summary;
                this.rooms = this.rooms.concat(rooms);
            } finally {
                if (this.loading) this.loading = false;
            }
        },
        searchMore() {
            this.page += 1;
        },
    },
};
</script>
